<template>
	<div class="container">
		<div class="title">
			<div class="text">更新摩托车类型</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">图标</div>
				<div class="right">
					<div class="upload-img">
						<input class="file" type="file" value=""  @change="updateLogo"/>
						<img class="img" :src="info.photo" :onerror="defaultImg" >
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input type="text" class="input" v-model="info.name" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">维文名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_ug" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">哈文名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_kz" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">排序</div>
				<div class="right">
					<input type="number" class="input" v-model="info.sort" placeholder="">
				</div>
			</div>
			<div class="btn" v-if="info.id">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateTruckType
	} from "@/api/truck";
	import {
		uploadFile
	} from "@/api/common";
	export default {
		data() {
			return {
				info: {},
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info))
			} else {
				this.showToast("获取数据失败")
			}
		},
		methods: {
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.photo || this.info.photo.indexOf("http") != 0) {
					this.showToast("请上传图片")
					return
				}
				if (!this.info.name || !this.info.name.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.name_ug || !this.info.name_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.name_kz || !this.info.name_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				this.info.sort *=1;
				console.log(this.info)
				if(this.info){
					return
				}
				//发送请求
				isLoad = true;
				updateTruckType(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("更新成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			updateLogo(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info.photo = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}
			
						});
					};
				}
			},
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	
	.container {
		
		.poster-btn {
			height: 30px;
			line-height: 30px;
			width: 80px;
			text-align: center;
			border: none;
			background-color: #5e9ec0;
			color: #fff;
			border-radius: 10px;
			cursor: pointer;
			margin-right: 18px;
		}
		
		.poster-loading {
			height: 30px;
			line-height: 30px;
			color: #dd524d;
		}
	}
</style>
